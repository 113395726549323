import { API } from "aws-amplify";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import Context from "./Context";

const ContextProvider = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [previousClasses, setPreviousClasses] = useState([]);
  const [userList, setUserList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [cartState, setCartState] = useState({
    subtotal: 0,
    productItems: [],
    quantities: [],
    currencySymbol: '$',
  });

  useEffect(() => {
    if (isAuth) {
      const onLoad = async () => {
        try {
          const classes = await API.get("user", "/user/upcoming-schedule/Bworkz");
          setUpcomingClasses(classes);
        } catch (e) {
          setUpcomingClasses([]);
          console.log(e);
        }

        try {
          const classes = await API.get("user", "/user/previous-schedule/Bworkz");
          setPreviousClasses(classes);
        } catch (e) {
          setPreviousClasses([]);
          console.log(e);
        }

        try {
          const list = await API.get("user", "/admin/profile-list/Bworkz");
          setUserList(list);
        } catch (e) {
          console.log(e);
          setUserList([]);
        }
      };

      onLoad();
    }
  }, [isAuth]);

  useEffect(() => {
    API.get("user", "/any/products/Bworkz")
      .then((list) => {
        console.log(list);
        setProductList(list);
      })
      .catch((e) => {
        console.log(e);
        setUserList([]);
      });

    API.get("user", "/any/instructor-list/Bworkz").then(data => {
      console.log(data)
      setInstructorList(data);
    }).catch(e => {
      console.log(e)
    })
  }, []);

  useEffect(() => {
    if (userData.cognitoId) {
      getCartItems(userData.cognitoId);
    }
  }, [userData.cognitoId]);
  const getCartItems = async (cognitoId) => {
    try {
      const response = await API.get('awsaiapp', `/any/getcartitems/Bworkz/${cognitoId}`);
      setCartItems(response);
      setItemCount(response.length);
      if (Array.isArray(response) && response.length > 0) {
        const quantities = response.map(() => 1);
        const subtotal = response.reduce((total, item, index) => total + (item.amount / 100) * quantities[index], 0);
        const currencySymbol = response[0].currency === 'INR' ? '₹' : '$';
        setCartState({ productItems: response, quantities, subtotal, currencySymbol });
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  };

  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if (isAuth) {
      fetchPlaylists();
      fetchVideos()
    }
    // eslint-disable-next-line
  }, [isAuth]);

  const fetchVideos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await API.get('user', '/user/fetch-videos/Bworkz');
      if (response.videos && Array.isArray(response.videos)) {
        setVideos(response.videos);
      } else {
        console.error('Unexpected response format:', response);
        setError('Unexpected response format');
      }
    } catch (err) {
      console.error('Error fetching videos:', err);
      setError('Failed to fetch videos');
    } finally {
      setLoading(false);
    }
  }, []);


  const [playlists, setPlaylists] = useState([]);
  const [loadingPlaylist, setLoadingPlaylist] = useState(true);
  const [errorPlaylist, setErrorPlaylist] = useState(null);

    const fetchPlaylists = async () => {
      setLoadingPlaylist(true);
      try {
        const response = await API.get('user', '/user/get-playlist/Bworkz');
        console.log(response)
        const uniquePlaylists = response.playlists.reduce((acc, playlist) => {
          if (!acc.some((p) => p.playlistName === playlist.playlistName)) {
            acc.push(playlist);
          }
          return acc;
        }, []);
        setPlaylists(uniquePlaylists);
      } catch (err) {
        console.error('Error fetching playlists:', err);
        setErrorPlaylist('Failed to fetch playlists');
      } finally {
        setLoadingPlaylist(false);
      }
    };

  
  const setIsAuthFn = (data) => {
    setIsAuth(data);
  };

  const setUserDataFn = (data) => {
    setUserData(data);
  };

  const setLoaderFn = (data) => {
    setLoader(data);
  };

  const setUpcomingClassesFn = (classes) => {
    setUpcomingClasses(classes);
  };

  const setPreviousClassesFn = (classes) => {
    setPreviousClasses(classes);
  };

  const setUserListFn = (list) => {
    setUserList(list);
  };

  const checkSubscriptionStatus = useMemo(() => {
    if (userData && userData.userType) {
      const subscriptionType = userData.userType;
      const subscriptionStatus = userData.status;
      console.log("Subscription Type:", subscriptionType);
      if (subscriptionType === "admin") {
        return { borderColor: "green" };
      } else if (subscriptionType === "instructor") {
        return { borderColor: "blue" };
      } else if ((subscriptionType === "member") && (subscriptionStatus === "Active")) {
        return { borderColor: "#1b7571" };
      }
    }
    // Return the default style for non-admin and non-active accounts
    return { borderColor: "red" };
  }, [userData]);



  const ContextData = {
    isAuth: isAuth,
    setIsAuth: setIsAuthFn,
    userData: userData,
    setUserData: setUserDataFn,
    util: {
      loader: loader,
      setLoader: setLoaderFn,
    },
    upcomingClasses: upcomingClasses,
    setUpcomingClasses: setUpcomingClassesFn,
    previousClasses: previousClasses,
    setPreviousClasses: setPreviousClassesFn,
    userList: userList,
    setUserList: setUserListFn,
    instructorList: instructorList,
    setInstructorList: () => { },
    getCartItems: getCartItems,
    productList: productList,
    setProductList: () => { },
    checkSubscriptionStatus: checkSubscriptionStatus,
    cartItems,
    itemCount,
    cartState,
    setCartState,
    videos,
    setVideos,
    error,
    setError,
    loading,
    setLoading,
    playlists,
    setPlaylists,
    errorPlaylist,
    setErrorPlaylist,
    loadingPlaylist,
    setLoadingPlaylist,
    fetchVideos: fetchVideos,
    fetchPlaylists:fetchPlaylists
  };

  return (
    <Context.Provider value={ContextData}>{props.children}</Context.Provider>
  );
};

export { ContextProvider };

