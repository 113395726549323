import React from 'react';
import { X, Upload, Check } from 'lucide-react';

const ProgressNotification = ({ progress, onClose, status }) => {
  const getStatusColor = () => {
    if (status === 'completed') return 'bg-green-500';
    if (status === 'error') return 'bg-red-500';
    return 'bg-black';
  };

  return (
    <div className="fixed bottom-4 right-4 w-80 bg-[#ffffff] shadow p-2 px-4 transform transition-transform duration-300 ease-in-out z-10 max600:w-full max600:h-[5rem] max600:absolute max600:top-1 max600:left-0">
      <div className="flex justify-between items-start mb-2">
        <div className="flex items-center gap-2">
          {status === 'completed' ? (
            <Check className="text-green-500" size={20} />
          ) : (
            <Upload className="text-gray-900" size={20} />
          )}
          <span className="font-medium text-gray-900">
            {status === 'completed' ? 'Upload Complete' : 'Uploading Video'}
          </span>
        </div>
        <button 
          onClick={onClose}
          className="text-gray-800 hover:text-gray-600"
        >
          <X size={20} />
        </button>
      </div>
      
      <div className="w-full bg-[#e9e9e9] rounded-full h-2 mb-2">
        <div 
          className={`h-2 rounded-full ${getStatusColor()} transition-all duration-300`}
          style={{ width: `${progress}%` }}
        />
      </div>
      
      <div className="flex justify-between text-sm">
        <span className="text-gray-900">
          {status === 'completed' ? 'Successfully uploaded' : `${Math.round(progress)}% complete`}
        </span>
        {status !== 'completed' && (
          <span className="text-gray-900">{Math.round(progress)}%</span>
        )}
      </div>
    </div>
  );
};

export default ProgressNotification;