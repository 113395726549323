import React from "react";
import { Route, Routes } from "react-router-dom";
import DashBoard from "./pages/DashBoard";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import About from "./pages/AboutUs";
// import Services from "./pages/Services";
import Instructor from "./pages/Instructor";
import Subscription from "./pages/Subscription";
import Query from "./pages/Query";
import ErrorPage from "./pages/Error";
import ForgotPassword from "./pages/ForgotPassword";
import PaymentFailed from "./pages/PaymentFailed";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import Logout from "./pages/Logout";
import ProfileUpdate from "./Components/DashBoard/ProfileUpdate";
import UserProfile from "./Components/DashBoard/UserProfile";
import DevAuth from "./Components/Auth/dev";
import Cart from "./Components/Payment/Cart"

const RoutesContainer = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dashboard" element={<DashBoard />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/instructor" element={<Instructor />} />
      {/* <Route path="/services" element={<Services />} /> */}
      <Route
        path="/login"
        element={
          process.env.REACT_APP_STAGE === "PROD" ? <Login /> : <DevAuth />
        }
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/query" element={<Query />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/paymentsuccessful" element={<PaymentSuccessful />} />
      <Route path="/paymentfailed" element={<PaymentFailed />} />
      <Route path="/settings" element={<ProfileUpdate />} />
      <Route path="/userProfile" element={<UserProfile />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/payment" element={<Cart />} />
    </Routes>
  );
};

export default RoutesContainer;
