import React, { useState, useContext } from 'react';
import { Search, Menu } from 'lucide-react';
import './choreography.css';
import UploadModal from './UploadModal';
import VideoCard from './VideoCard';
import Play from './Play';
import Playlist from './Playlist';
import Context from '../../../Context/Context';
import SkeletonGrid from './SkeletonGrid';

export default function Main() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { videos, error, loading, userData } = useContext(Context);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [view, setView] = useState('grid');
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [isMobilePlaylistOpen, setIsMobilePlaylistOpen] = useState(false);

  // CSS classes for the sliding animation
  const slideClasses = `
    fixed right-0 top-20 h-[80vh] bg-[black] z-50 transition-transform duration-300 ease-in-out
    w-[300px] transform ${isMobilePlaylistOpen ? 'translate-x-0' : 'translate-x-full'}
    shadow-[-4px_0_6px_-1px_rgba(0,0,0,0.1)]
  `;

  const filteredVideos = React.useMemo(() => {
    if (!Array.isArray(videos)) return [];

    let filtered = videos;

    if (selectedPlaylist && selectedPlaylist.videos) {
      filtered = videos.filter(video =>
        selectedPlaylist.videos.some(playlistVideo =>
          playlistVideo.songName === video.songName &&
          playlistVideo.title === video.title &&
          playlistVideo.videoType_partNo === video.videoType_partNo &&
          playlistVideo.choreographer === video.choreographer
        )
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(video =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filtered;
  }, [videos, selectedPlaylist, searchTerm]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setView('player');
    setIsMobilePlaylistOpen(false);
  };

  const handleClosePlayer = () => {
    setSelectedVideo(null);
    setView('grid');
  };

  const handlePlaylistSelect = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsMobilePlaylistOpen(false);
  };

  const toggleMobilePlaylist = () => {
    setIsMobilePlaylistOpen(!isMobilePlaylistOpen);
  };

  // Background overlay when playlist is open
  const overlayClasses = `
    fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300
    ${isMobilePlaylistOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}
  `;

  return (
    <div className="w-full max-h-screen scrollbar-hide mb-[6rem] p-4 max600:h-screen max600:mb-6 max600:-mt-8 ">
      <div className="max-w-8xl">
        <div className="relative mb-8 w-full flex justify-between items-center max600:flex-col-reverse max600:gap-3">
          <div className="flex w-1/3 max600:w-full items-center gap-2">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search videos by song names..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-12 py-2 rounded-[10px] border bg-[#f5f5f5] border-gray-300"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <button
              onClick={toggleMobilePlaylist}
              className="hidden max600:flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            >
              <Menu className="w-5 h-5 text-gray-600" />
            </button>
          </div>
          {selectedPlaylist && (
            <div className="flex items-center -mb-6 mr-8 max600:mb-0 max600:mr-0">
              <button
                onClick={() => setSelectedPlaylist(null)}
                className="text-sm inter text-gray-200 poppins hover:text-gray-800 px-3 py-1 rounded-md bg-gray-800 hover:bg-gray-200 mr-4"
              >
                Clear Playlist
              </button>
              <span className="text-gray-600 poppins text-[0.9rem]">
                Showing: {selectedPlaylist.playlistName} ({filteredVideos.length} videos)
              </span>
            </div>
          )}
          {userData.userType === 'admin' && (
            <div className="max600:w-full">
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-[#f0da15] text-white font-bold tracking-[0.5px] p-2 rounded-md max600:w-full"
              >
                Upload Videos
              </button>
            </div>
          )}
          <UploadModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        </div>

        <div className="flex gap-8 h-[80vh] relative">
          {loading ? (
            <SkeletonGrid />
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : view === 'grid' ? (
            <div className="w-full flex flex-wrap gap-4 h-[83vh] overflow-y-auto scrollbar-hide max600:justify-center max600:px-4">
              {filteredVideos.length > 0 ? (
                filteredVideos.map((video, index) => (
                    <VideoCard
                      key={index}
                      video={video}
                      onClick={handleVideoClick}
                    />
                ))
              ) : (
                <div className="w-full text-center text-gray-500 mt-8">
                  {selectedPlaylist ? 'No videos in this playlist' : 'No videos found'}
                </div>
              )}
            </div>
          ) : (
            <div className="flex-1 min-w-0">
              <Play
                video={selectedVideo}
                onClose={handleClosePlayer}
              />
            </div>
          )}

          {/* Desktop Playlist */}
          <div className="w-[350px] bg-[#ffffffbd] rounded-lg shadow-md flex flex-col max600:hidden">
            <div className="p-3">
              <h3 className="text-[1.2rem] text-gray-600 inter tracking-wide font-semibold border-b-2 border-[#dfdfdf] pb-2">
                Playlist
              </h3>
            </div>
            <div className="flex-1 overflow-y-auto scrollbar-custom">
              <Playlist
                onSelectPlaylist={handlePlaylistSelect}
                selectedPlaylist={selectedPlaylist}
              />
            </div>
          </div>

          {/* Mobile Playlist Overlay */}
          <div
            className={overlayClasses}
            onClick={() => setIsMobilePlaylistOpen(false)}
          />

          {/* Mobile Playlist Slide Panel */}
          <div className={slideClasses}>
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-[1.2rem] text-gray-600 inter tracking-wide font-semibold">
                  Playlist
                </h3>
                <button
                  onClick={toggleMobilePlaylist}
                  className="text-gray-500 hover:bg-gray-100 p-2 rounded-full transition-colors"
                >
                  <Menu className="w-6 h-6" />
                </button>
              </div>
              <div className="overflow-y-auto h-[calc(80vh-120px)]">
                <Playlist
                  onSelectPlaylist={handlePlaylistSelect}
                  selectedPlaylist={selectedPlaylist}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}