import React, { useContext, useEffect, useRef } from 'react'
import { API } from 'aws-amplify'
// import Razorpay from "razorpay";

// import SubscriptionPopup from "../../pages/SubscriptionPopup";
import { useNavigate } from 'react-router-dom'
import Context from '../../Context/Context'

const RazorpayPayment = ({ productId }) => {
  const razorpay = useRef()
  const UtilCtx = useContext(Context).util
  const Ctx = useContext(Context)
  const UserCtx = useContext(Context).userData
console.log(UserCtx);
  const Navigate = useNavigate()

  useEffect(() => {
    // new window.Razorpay();
    console.log(window.razorpay)
  }, [])

  const handleSubscribe = async () => {
    UtilCtx.setLoader(true)
    let response
    try {
      response = await API.put(
        'user',
        "/user/billing/regular/Bworkz",
        {
          body: {
            productId: productId
          }
        }
      )
      // console.log("hii",response)
    } catch (e) {
      console.log(e)
      UtilCtx.setLoader(false)
    }
    try {
      const options = {
        key_id:'rzp_live_KBQhEinczOWwzs',
        amount: response.amount,
        currency: response.currency,
        name: "Bworkz".toUpperCase(),
        description: response.subscriptionType,
        image: `https://institution-utils.s3.amazonaws.com/public/Bworkz/images/Logo.45979bb075e157a3950b7c17a8ed50c9.svg`,
        order_id: response.orderId,
        handler: function (response) {
          alert(response.razorpay_payment_id)
          alert(response.razorpay_order_id)
          alert(response.razorpay_signature)
          const verify = async () => {
            UtilCtx.setLoader(true)
            try {
              let resBody = {
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature
              }
              const res = await API.put(
                'user',
                `/user/billing/regular/verify/Bworkz`,
                {
                  body: resBody
                }
              )
              const tempUserdata = await API.get(
                'user',
                `/user/profile/Bworkz`
              )
              Ctx.setUserData(tempUserdata)
              if (res.signatureIsValid) {
                Navigate('/dashboard', { state: { isReload: true } })
              } else {
                alert(
                  'Transaction Failed If your Amount was Deducted then Contact us'
                )
              }
              // alert(res);
              UtilCtx.setLoader(false)
            } catch (e) {
              console.log(e)
              UtilCtx.setLoader(false)
            }
          }
          verify()
        },
        prefill: {
          name: UserCtx.userName,
          email: UserCtx.emailId,
          contact: '9999999999'
        },
        notes: {
          address: 'Razorpay Corporate Office'
        },
        theme: {
          color: '#FDCF08'
        }
      }
      const rzp1 = new window.Razorpay(options)
      rzp1.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        console.log(response)
        UtilCtx.setLoader(false)
      })
      rzp1.open()
      UtilCtx.setLoader(false)
    } catch (e) {
      console.log(e)
      UtilCtx.setLoader(false)
    }
  }

  // const handleSubscribe = () => {
  //   Navigate("/subscribe");
  // };

  return (
    <div className="z-1">
      <button
        ref={razorpay}
        className={`w-[15rem] text-white px-12 py-2 rounded-2xl hover:text-[#FDCF08] hover:bg-white hover:border-lightPrimaryColor hover:border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw] cursor-pointer`}
        style={{
          backgroundColor: "#FDCF08"
        }}
        onClick={handleSubscribe}
      >
        Subscribe
      </button>
    </div>
  )
}

export default RazorpayPayment