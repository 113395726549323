import React, { useRef, useEffect } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";

export default function PayPalSubscription({ planId, duration }) {
  const paypalRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          shape: "rect",
          color: "gold",
          layout: "vertical",
          label: "subscribe",
          tagline: "false",
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            intent: "subscription",
            plan_id: planId,
          });
        },
        onApprove: async (data) => {
          try {
            const addDuration = duration * 24 * 60 * 60 * 1000; 
            const product = `BworkZ Subscription (${duration / 30} months)`;
            const joiningDate = Date.now();
            const renewDate = Date.now() + addDuration;
            const subscriptionId = data.subscriptionID;

            await paypalData({
              product,
              joiningDate,
              renewDate,
              subscriptionId,
            });

            navigate("/paymentsuccessful");
          } catch (e) {
            console.error("Payment processing error:", e);
            navigate("/paymentfailed");
          }
        },
        onError: (err) => {
          console.error("PayPal error:", err);
          navigate("/paymentfailed");
        },
      })
      .render(paypalRef.current);
  }, [planId, duration, navigate]);

  const paypalData = async (postData) => {
    return API.put("user", `/user/payment-update/Bworkz`, {
      body: postData,
    });
  };

  return <div id={`paypal-button-container-${planId}`} ref={paypalRef}></div>;
}
