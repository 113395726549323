import {useContext, useState} from "react";
// import {Select} from "flowbite-react";
// import {PrimaryButton} from "../../../common/Inputs";
import {API, Auth} from "aws-amplify";
// import {toast} from "react-toastify";
import Context from "../../../Context/Context";
import {useNavigate} from "react-router-dom";
import Header from "../../../Components/NavBar";

const DevAuth = () => {
  const options = [
    { userType: 'admin', email: 'admin@tester.com' },
    { userType: 'instructor', email: 'instructor@tester.com' },
    { userType: 'user', email: 'user@tester.com' },
  ]

  const { util, setUserData, setIsAuth } = useContext(Context);
  const InstitutionId = 'Bworkz';
  const [email, setEmail] = useState(options[0].email);
  const navigate = useNavigate();
  // const { "*": link } = useParams();

  const handleLogin = async (e) => {
    e.preventDefault();
    util.setLoader(true);
    try {
      await Auth.signIn(email, 'Password@123');

      const userdata = await API.get(
        'user',
        `/user/profile/${InstitutionId}`,
        {}
      );
      setUserData(userdata);
      setIsAuth(true);
      util.setLoader(false);

      alert('Logged in');
      // onAuthLoad(true, InstitutionId);
      navigate('/dashboard');
    } catch (e) {
      console.log(e);
      alert('Unknown error occurred');
    } finally {
      util.setLoader(false);
    }
  }

  return (
    <div>
      <Header />
      <div className='w-80 mt-8 mx-auto flex flex-col gap-4'>
        <p>Login as: </p>
        <select
          className='bg-gray-400'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        >
          {options.map((option, index) => (
            <option key={index} value={option.email}>{option.userType}</option>
          ))}
        </select>
        <button onClick={handleLogin}> Login </button>
      </div>
    </div>
  )
}

export default DevAuth;